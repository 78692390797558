import React from 'react';
import Layout from "../components/layout"
import typStyles from "../styles/pages/typ/_typ.module.scss"
import HeaderTitle from '../components/_titles/_header_title';
// import {navigate} from 'gatsby'

const Typ = () => {
    
    return(
        <Layout>
            <div className={typStyles.typ__container}>
                <div className={typStyles.main__content}>
                    <HeaderTitle title="Hemos recibido tus datos." _classTitle="header__title_typ"/>
                    <div className={typStyles.typ__content}>
                        <p className={typStyles.typ__main_text}>
                            Uno de nuestros asesores se pondrá en contacto contigo en un plazo máximo de 24 horas.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Typ
